import React from "react";
import { useNavigate } from "react-router-dom";

// Internal imports
import "./UserProfilePopper.scss";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";

type UserProfilePopperProps = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  handledClickedItem: () => void;
};

export const UserProfilePopper = ({
  firstName,
  lastName,
  email,
  handledClickedItem,
}: UserProfilePopperProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    handledClickedItem();
    navigate("/");
  };

  return (
    <div className="user-profile-container">
      <div className="name-email">
        <h6 className="user-name">
          {" "}
          {firstName} {lastName || ""}
        </h6>
        <p className="user-email">{email}</p>
      </div>
      <hr className="popper-hr-line" />
      <div className="user-profile-opt">
        <li className="user-opt" onClick={handledClickedItem}>
          User Profile
        </li>
        {browserSupportsWebAuthn() && (
          <li
            className="user-opt"
            onClick={() => {
              navigate("/settings");
            }}
          >
            Settings
          </li>
        )}
      </div>
      <hr className="popper-hr-line" />
      <li className="logout" onClick={handleLogout}>
        Logout
      </li>
    </div>
  );
};
