export const API = {
  VENDOR: {
    VENDOR_TYPES: "vendor-types",
    HOTEL_REGISTRATION: "hotel/hotel-vendor/registration-request",
    TRANSPORTL_REGISTRATION: "transport/transport-vendor/registration-request",
    GET_VENDOR_BY_ID: "vendor",
    UPDATE_PROFILE_IMAGE: "vendor/upload-profile-image",
    TRASNPORT_DETAILS: "transport-details",
    CREATE_TRASNPORT_DETAILS: "transport-detail",
    DELETE_TRASNPORT_DETAIL: "transport-detail",
    LIST_VENDORS: "vendors",
    UPLOAD_VEHICLE_IMAGE: "transport-detail/upload-vehicle-image",
    PORT_CRUD: "port",
    AIRPORT_CRUD: "airport",
    BRAND_CRUD: "brand",
    VEHICLE_CATEGORY_CRUD: "transport/vehicle-category",
    VEHICLE_CATEGORY_LIST: "transport/vehicle-categories",
    AMENITIES_CRUD: "amenities",
  },
  TRANSPORT: {
    GET_TRANSPORT_DETAILS_BY_VENDORID: "transport/transport-vendor/all-details",
    UPDATE_TRANSPORT_PROFILE_DATA_BY_VENDORID: "transport/transport-vendor",
    TRANSPORT_VEHICLE_CRUD: "transport/vehicle",
    TRANSPORT_CONTRACT_CRUD: "transport/contract",
  },
  HOTEL: {
    GET_HOTEL_DETAILS_BY_VENDORID: "hotel/hotel-vendor/all-details",
    UPDATE_DATA_BY_VENDORID: "hotel/hotel-vendor",
    //old update
    HOTEL_DETAILS: "hotel-detail/vendor",
    CREATE_UPDATE: "hotel-detail/updateOrCreate",
    HOTEL_DETAIL_BY_ID: "hotel-detail",
    HOTEL_CONTACTS_BY_ID: "contact/hotel",
    HOTEL_CONTACTS_CREATE_UPDATE: "hotel/contact/updateOrCreate",
    HOTEL_ROOMS_CREATE_UPDATE: "hotel/hotel-room/updateOrCreate",
    HOTE_MEAL_ALLOWANCE_CREATE_UPDATE: "hotel/meal-allowance/updateOrCreate",
    CREATE_HOTEL_DETAIL: "hotel-detail",
    DELETE_HOTEL_DETAIL: "hotel-detail",
    UPLOAD_HOTEL_IMAGE: "hotel-detail/upload-hotel-image",
    HOTEL_CONTACTS_DELETE: "hotel/contact/",
    HOTEL_ROOM_DELETE: "hotel/hotel-room/",
    HOTEL_MEAL_ALLOWANCE_DELETE: "hotel/meal-allowance/",
  },
  AUTH: {
    LOGIN: "auth/login",
    CHANGE_PASSWORD: "auth/change-password",
    REFRESH_AUTH_TOKENS: "auth/refresh-auth-tokens",
    FORGOT_PASSWORD: "auth/forgot-password",
    FORGOT_PASSWORD_CONFIRM: "auth/forgot-password-confirm",
  },
  ADMIN: {
    APPROVE_REGISTRATION_REQUEST: "admin/approve-registration-request",
    LIST_CARNIVAL_ADMINS: "carnival-admins",
    CREATE_CARNIVAL_ADMIN: "admin/create-carnival-admin",
  },
  REGISTRATION_REQUEST: {
    LIST: `hotel/hotel-vendor/registration-requests`,
    COUNT: `hotel/hotel-vendor/registration-request-count`,
    UPLOAD_PROFILE_IMAGE: "registration-request/upload-profile-image/",
  },
  CARNIVAL_ADMIN: {
    LIST_SERVICE_REQUESTS: "service-requests",
    CREATE_SERVICE_REQUEST: "service-request",
  },
  CREATE_EMPLOYEE: "vendor-employee",
  LIST_VENDOR_EMPLOYEE: "vendor-employee",
  LIST_EMPLOYEE: "vendor-employees",
};
