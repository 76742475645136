export const timeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const checkin_checkout_titmings = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const currencies = [
  {
    value: "USD",
    symbol: "$",
    code: "USD",
    label: "USD",
    order: 1,
    name: "US Dollar",
  },
  {
    value: "EUR",
    symbol: "€",
    code: "EUR",
    label: "EUR",
    order: 2,
    name: "Euro",
  },
  {
    value: "AED",
    symbol: "AED",
    code: "AED",
    label: "AED",
    order: 9999,
    name: "United Arab Emirates Dirham",
  },
  {
    value: "AFN",
    symbol: "Af",
    code: "AFN",
    label: "AFN",
    order: 9999,
    name: "Afghan Afghani",
  },
  {
    value: "ALL",
    symbol: "ALL",
    code: "ALL",
    label: "ALL",
    order: 9999,
    name: "Albanian Lek",
  },
  {
    value: "AMD",
    symbol: "AMD",
    code: "AMD",
    label: "AMD",
    order: 9999,
    name: "Armenian Dram",
  },
  {
    value: "ARS",
    symbol: "AR$",
    code: "ARS",
    label: "ARS",
    order: 9999,
    name: "Argentine Peso",
  },
  {
    value: "AUD",
    symbol: "AU$",
    code: "AUD",
    label: "AUD",
    order: 9999,
    name: "Australian Dollar",
  },
  {
    value: "AZN",
    symbol: "man.",
    code: "AZN",
    label: "AZN",
    order: 9999,
    name: "Azerbaijani Manat",
  },
  {
    value: "BAM",
    symbol: "KM",
    code: "BAM",
    label: "BAM",
    order: 9999,
    name: "Bosnia-Herzegovina Convertible Mark",
  },
  {
    value: "BDT",
    symbol: "Tk",
    code: "BDT",
    label: "BDT",
    order: 9999,
    name: "Bangladeshi Taka",
  },
  {
    value: "BGN",
    symbol: "BGN",
    code: "BGN",
    label: "BGN",
    order: 9999,
    name: "Bulgarian Lev",
  },
  {
    value: "BHD",
    symbol: "BD",
    code: "BHD",
    label: "BHD",
    order: 9999,
    name: "Bahraini Dinar",
  },
  {
    value: "BIF",
    symbol: "FBu",
    code: "BIF",
    label: "BIF",
    order: 9999,
    name: "Burundian Franc",
  },
  {
    value: "BND",
    symbol: "BN$",
    code: "BND",
    label: "BND",
    order: 9999,
    name: "Brunei Dollar",
  },
  {
    value: "BOB",
    symbol: "Bs",
    code: "BOB",
    label: "BOB",
    order: 9999,
    name: "Bolivian Boliviano",
  },
  {
    value: "BRL",
    symbol: "R$",
    code: "BRL",
    label: "BRL",
    order: 9999,
    name: "Brazilian Real",
  },
  {
    value: "BWP",
    symbol: "BWP",
    code: "BWP",
    label: "BWP",
    order: 9999,
    name: "Botswanan Pula",
  },
  {
    value: "BYN",
    symbol: "Br",
    code: "BYN",
    label: "BYN",
    order: 9999,
    name: "Belarusian Ruble",
  },
  {
    value: "BZD",
    symbol: "BZ$",
    code: "BZD",
    label: "BZD",
    order: 9999,
    name: "Belize Dollar",
  },
  {
    value: "CDF",
    symbol: "CDF",
    code: "CDF",
    label: "CDF",
    order: 9999,
    name: "Congolese Franc",
  },
  {
    value: "CHF",
    symbol: "CHF",
    code: "CHF",
    label: "CHF",
    order: 9999,
    name: "Swiss Franc",
  },
  {
    value: "CLP",
    symbol: "CL$",
    code: "CLP",
    label: "CLP",
    order: 9999,
    name: "Chilean Peso",
  },
  {
    value: "CNY",
    symbol: "CN¥",
    code: "CNY",
    label: "CNY",
    order: 9999,
    name: "Chinese Yuan",
  },
  {
    value: "COP",
    symbol: "CO$",
    code: "COP",
    label: "COP",
    order: 9999,
    name: "Colombian Peso",
  },
  {
    value: "CRC",
    symbol: "₡",
    code: "CRC",
    label: "CRC",
    order: 9999,
    name: "Costa Rican Colón",
  },
  {
    value: "CVE",
    symbol: "CV$",
    code: "CVE",
    label: "CVE",
    order: 9999,
    name: "Cape Verdean Escudo",
  },
  {
    value: "CZK",
    symbol: "Kč",
    code: "CZK",
    label: "CZK",
    order: 9999,
    name: "Czech Republic Koruna",
  },
  {
    value: "DJF",
    symbol: "Fdj",
    code: "DJF",
    label: "DJF",
    order: 9999,
    name: "Djiboutian Franc",
  },
  {
    value: "DKK",
    symbol: "Dkr",
    code: "DKK",
    label: "DKK",
    order: 9999,
    name: "Danish Krone",
  },
  {
    value: "DOP",
    symbol: "RD$",
    code: "DOP",
    label: "DOP",
    order: 9999,
    name: "Dominican Peso",
  },
  {
    value: "DZD",
    symbol: "DA",
    code: "DZD",
    label: "DZD",
    order: 9999,
    name: "Algerian Dinar",
  },
  {
    value: "EEK",
    symbol: "Ekr",
    code: "EEK",
    label: "EEK",
    order: 9999,
    name: "Estonian Kroon",
  },
  {
    value: "EGP",
    symbol: "EGP",
    code: "EGP",
    label: "EGP",
    order: 9999,
    name: "Egyptian Pound",
  },
  {
    value: "ERN",
    symbol: "Nfk",
    code: "ERN",
    label: "ERN",
    order: 9999,
    name: "Eritrean Nakfa",
  },
  {
    value: "ETB",
    symbol: "Br",
    code: "ETB",
    label: "ETB",
    order: 9999,
    name: "Ethiopian Birr",
  },
  {
    value: "GBP",
    symbol: "£",
    code: "GBP",
    label: "GBP",
    order: 9999,
    name: "British Pound Sterling",
  },
  {
    value: "GEL",
    symbol: "GEL",
    code: "GEL",
    label: "GEL",
    order: 9999,
    name: "Georgian Lari",
  },
  {
    value: "GHS",
    symbol: "GH₵",
    code: "GHS",
    label: "GHS",
    order: 9999,
    name: "Ghanaian Cedi",
  },
  {
    value: "GNF",
    symbol: "FG",
    code: "GNF",
    label: "GNF",
    order: 9999,
    name: "Guinean Franc",
  },
  {
    value: "GTQ",
    symbol: "GTQ",
    code: "GTQ",
    label: "GTQ",
    order: 9999,
    name: "Guatemalan Quetzal",
  },
  {
    value: "HKD",
    symbol: "HK$",
    code: "HKD",
    label: "HKD",
    order: 9999,
    name: "Hong Kong Dollar",
  },
  {
    value: "HNL",
    symbol: "HNL",
    code: "HNL",
    label: "HNL",
    order: 9999,
    name: "Honduran Lempira",
  },
  {
    value: "HRK",
    symbol: "kn",
    code: "HRK",
    label: "HRK",
    order: 9999,
    name: "Croatian Kuna",
  },
  {
    value: "HUF",
    symbol: "Ft",
    code: "HUF",
    label: "HUF",
    order: 9999,
    name: "Hungarian Forint",
  },
  {
    value: "IDR",
    symbol: "Rp",
    code: "IDR",
    label: "IDR",
    order: 9999,
    name: "Indonesian Rupiah",
  },
  {
    value: "ILS",
    symbol: "₪",
    code: "ILS",
    label: "ILS",
    order: 9999,
    name: "Israeli New Sheqel",
  },
  {
    value: "INR",
    symbol: "Rs",
    code: "INR",
    label: "INR",
    order: 9999,
    name: "Indian Rupee",
  },
  {
    value: "IQD",
    symbol: "IQD",
    code: "IQD",
    label: "IQD",
    order: 9999,
    name: "Iraqi Dinar",
  },
  {
    value: "IRR",
    symbol: "IRR",
    code: "IRR",
    label: "IRR",
    order: 9999,
    name: "Iranian Rial",
  },
  {
    value: "ISK",
    symbol: "Ikr",
    code: "ISK",
    label: "ISK",
    order: 9999,
    name: "Icelandic Króna",
  },
  {
    value: "JMD",
    symbol: "J$",
    code: "JMD",
    label: "JMD",
    order: 9999,
    name: "Jamaican Dollar",
  },
  {
    value: "JOD",
    symbol: "JD",
    code: "JOD",
    label: "JOD",
    order: 9999,
    name: "Jordanian Dinar",
  },
  {
    value: "JPY",
    symbol: "¥",
    code: "JPY",
    label: "JPY",
    order: 9999,
    name: "Japanese Yen",
  },
  {
    value: "KES",
    symbol: "Ksh",
    code: "KES",
    label: "KES",
    order: 9999,
    name: "Kenyan Shilling",
  },
  {
    value: "KHR",
    symbol: "KHR",
    code: "KHR",
    label: "KHR",
    order: 9999,
    name: "Cambodian Riel",
  },
  {
    value: "KMF",
    symbol: "CF",
    code: "KMF",
    label: "KMF",
    order: 9999,
    name: "Comorian Franc",
  },
  {
    value: "KRW",
    symbol: "₩",
    code: "KRW",
    label: "KRW",
    order: 9999,
    name: "South Korean Won",
  },
  {
    value: "KWD",
    symbol: "KD",
    code: "KWD",
    label: "KWD",
    order: 9999,
    name: "Kuwaiti Dinar",
  },
  {
    value: "KZT",
    symbol: "KZT",
    code: "KZT",
    label: "KZT",
    order: 9999,
    name: "Kazakhstani Tenge",
  },
  {
    value: "LBP",
    symbol: "LB£",
    code: "LBP",
    label: "LBP",
    order: 9999,
    name: "Lebanese Pound",
  },
  {
    value: "LKR",
    symbol: "SLRs",
    code: "LKR",
    label: "LKR",
    order: 9999,
    name: "Sri Lankan Rupee",
  },
  {
    value: "LTL",
    symbol: "Lt",
    code: "LTL",
    label: "LTL",
    order: 9999,
    name: "Lithuanian Litas",
  },
  {
    value: "LVL",
    symbol: "Ls",
    code: "LVL",
    label: "LVL",
    order: 9999,
    name: "Latvian Lats",
  },
  {
    value: "LYD",
    symbol: "LD",
    code: "LYD",
    label: "LYD",
    order: 9999,
    name: "Libyan Dinar",
  },
  {
    value: "MAD",
    symbol: "MAD",
    code: "MAD",
    label: "MAD",
    order: 9999,
    name: "Moroccan Dirham",
  },
  {
    value: "MDL",
    symbol: "MDL",
    code: "MDL",
    label: "MDL",
    order: 9999,
    name: "Moldovan Leu",
  },
  {
    value: "MGA",
    symbol: "MGA",
    code: "MGA",
    label: "MGA",
    order: 9999,
    name: "Malagasy Ariary",
  },
  {
    value: "MKD",
    symbol: "MKD",
    code: "MKD",
    label: "MKD",
    order: 9999,
    name: "Macedonian Denar",
  },
  {
    value: "MMK",
    symbol: "MMK",
    code: "MMK",
    label: "MMK",
    order: 9999,
    name: "Myanma Kyat",
  },
  {
    value: "MOP",
    symbol: "MOP$",
    code: "MOP",
    label: "MOP",
    order: 9999,
    name: "Macanese Pataca",
  },
  {
    value: "MUR",
    symbol: "MURs",
    code: "MUR",
    label: "MUR",
    order: 9999,
    name: "Mauritian Rupee",
  },
  {
    value: "MXN",
    symbol: "MX$",
    code: "MXN",
    label: "MXN",
    order: 9999,
    name: "Mexican Peso",
  },
  {
    value: "MYR",
    symbol: "RM",
    code: "MYR",
    label: "MYR",
    order: 9999,
    name: "Malaysian Ringgit",
  },
  {
    value: "MZN",
    symbol: "MTn",
    code: "MZN",
    label: "MZN",
    order: 9999,
    name: "Mozambican Metical",
  },
  {
    value: "NAD",
    symbol: "N$",
    code: "NAD",
    label: "NAD",
    order: 9999,
    name: "Namibian Dollar",
  },
  {
    value: "NGN",
    symbol: "₦",
    code: "NGN",
    label: "NGN",
    order: 9999,
    name: "Nigerian Naira",
  },
  {
    value: "NIO",
    symbol: "C$",
    code: "NIO",
    label: "NIO",
    order: 9999,
    name: "Nicaraguan Córdoba",
  },
  {
    value: "NOK",
    symbol: "Nkr",
    code: "NOK",
    label: "NOK",
    order: 9999,
    name: "Norwegian Krone",
  },
  {
    value: "NPR",
    symbol: "NPRs",
    code: "NPR",
    label: "NPR",
    order: 9999,
    name: "Nepalese Rupee",
  },
  {
    value: "NZD",
    symbol: "NZ$",
    code: "NZD",
    label: "NZD",
    order: 9999,
    name: "New Zealand Dollar",
  },
  {
    value: "OMR",
    symbol: "OMR",
    code: "OMR",
    label: "OMR",
    order: 9999,
    name: "Omani Rial",
  },
  {
    value: "PAB",
    symbol: "B/.",
    code: "PAB",
    label: "PAB",
    order: 9999,
    name: "Panamanian Balboa",
  },
  {
    value: "PEN",
    symbol: "S/.",
    code: "PEN",
    label: "PEN",
    order: 9999,
    name: "Peruvian Nuevo Sol",
  },
  {
    value: "PHP",
    symbol: "₱",
    code: "PHP",
    label: "PHP",
    order: 9999,
    name: "Philippine Peso",
  },
  {
    value: "PKR",
    symbol: "PKRs",
    code: "PKR",
    label: "PKR",
    order: 9999,
    name: "Pakistani Rupee",
  },
  {
    value: "PLN",
    symbol: "zł",
    code: "PLN",
    label: "PLN",
    order: 9999,
    name: "Polish Zloty",
  },
  {
    value: "PYG",
    symbol: "₲",
    code: "PYG",
    label: "PYG",
    order: 9999,
    name: "Paraguayan Guarani",
  },
  {
    value: "QAR",
    symbol: "QR",
    code: "QAR",
    label: "QAR",
    order: 9999,
    name: "Qatari Rial",
  },
  {
    value: "RON",
    symbol: "RON",
    code: "RON",
    label: "RON",
    order: 9999,
    name: "Romanian Leu",
  },
  {
    value: "RSD",
    symbol: "din.",
    code: "RSD",
    label: "RSD",
    order: 9999,
    name: "Serbian Dinar",
  },
  {
    value: "RUB",
    symbol: "RUB",
    code: "RUB",
    label: "RUB",
    order: 9999,
    name: "Russian Ruble",
  },
  {
    value: "RWF",
    symbol: "RWF",
    code: "RWF",
    label: "RWF",
    order: 9999,
    name: "Rwandan Franc",
  },
  {
    value: "SAR",
    symbol: "SR",
    code: "SAR",
    label: "SAR",
    order: 9999,
    name: "Saudi Riyal",
  },
  {
    value: "SDG",
    symbol: "SDG",
    code: "SDG",
    label: "SDG",
    order: 9999,
    name: "Sudanese Pound",
  },
  {
    value: "SEK",
    symbol: "Skr",
    code: "SEK",
    label: "SEK",
    order: 9999,
    name: "Swedish Krona",
  },
  {
    value: "SGD",
    symbol: "S$",
    code: "SGD",
    label: "SGD",
    order: 9999,
    name: "Singapore Dollar",
  },
  {
    value: "SOS",
    symbol: "Ssh",
    code: "SOS",
    label: "SOS",
    order: 9999,
    name: "Somali Shilling",
  },
  {
    value: "SYP",
    symbol: "SY£",
    code: "SYP",
    label: "SYP",
    order: 9999,
    name: "Syrian Pound",
  },
  {
    value: "THB",
    symbol: "฿",
    code: "THB",
    label: "THB",
    order: 9999,
    name: "Thai Baht",
  },
  {
    value: "TND",
    symbol: "DT",
    code: "TND",
    label: "TND",
    order: 9999,
    name: "Tunisian Dinar",
  },
  {
    value: "TOP",
    symbol: "T$",
    code: "TOP",
    label: "TOP",
    order: 9999,
    name: "Tongan Paʻanga",
  },
  {
    value: "TRY",
    symbol: "TL",
    code: "TRY",
    label: "TRY",
    order: 9999,
    name: "Turkish Lira",
  },
  {
    value: "TTD",
    symbol: "TT$",
    code: "TTD",
    label: "TTD",
    order: 9999,
    name: "Trinidad and Tobago Dollar",
  },
  {
    value: "TWD",
    symbol: "NT$",
    code: "TWD",
    label: "TWD",
    order: 9999,
    name: "New Taiwan Dollar",
  },
  {
    value: "TZS",
    symbol: "TSh",
    code: "TZS",
    label: "TZS",
    order: 9999,
    name: "Tanzanian Shilling",
  },
  {
    value: "UAH",
    symbol: "₴",
    code: "UAH",
    label: "UAH",
    order: 9999,
    name: "Ukrainian Hryvnia",
  },
  {
    value: "UGX",
    symbol: "USh",
    code: "UGX",
    label: "UGX",
    order: 9999,
    name: "Ugandan Shilling",
  },
  {
    value: "UYU",
    symbol: "$U",
    code: "UYU",
    label: "UYU",
    order: 9999,
    name: "Uruguayan Peso",
  },
  {
    value: "UZS",
    symbol: "UZS",
    code: "UZS",
    label: "UZS",
    order: 9999,
    name: "Uzbekistan Som",
  },
  {
    value: "VEF",
    symbol: "Bs.F.",
    code: "VEF",
    label: "VEF",
    order: 9999,
    name: "Venezuelan Bolívar",
  },
  {
    value: "VND",
    symbol: "₫",
    code: "VND",
    label: "VND",
    order: 9999,
    name: "Vietnamese Dong",
  },
  {
    value: "XAF",
    symbol: "FCFA",
    code: "XAF",
    label: "XAF",
    order: 9999,
    name: "CFA Franc BEAC",
  },
  {
    value: "XOF",
    symbol: "CFA",
    code: "XOF",
    label: "XOF",
    order: 9999,
    name: "CFA Franc BCEAO",
  },
  {
    value: "YER",
    symbol: "YR",
    code: "YER",
    label: "YER",
    order: 9999,
    name: "Yemeni Rial",
  },
  {
    value: "ZAR",
    symbol: "R",
    code: "ZAR",
    label: "ZAR",
    order: 9999,
    name: "South African Rand",
  },
  {
    value: "ZMK",
    symbol: "ZK",
    code: "ZMK",
    label: "ZMK",
    order: 9999,
    name: "Zambian Kwacha",
  },
  {
    value: "ZWL",
    symbol: "ZWL$",
    code: "ZWL",
    label: "ZWL",
    order: 9999,
    name: "Zimbabwean Dollar",
  },
];
export const countries = [
  {
    value: "UNITED STATES OF AMERICA",
    label: "UNITED STATES OF AMERICA",
  },
  {
    value: "AFGHANISTAN",
    label: "AFGHANISTAN",
  },
  {
    value: "ÅLAND ISLANDS",
    label: "ÅLAND ISLANDS",
  },
  {
    value: "ALBANIA",
    label: "ALBANIA",
  },
  {
    value: "ALGERIA",
    label: "ALGERIA",
  },
  {
    value: "AMERICAN SAMOA",
    label: "AMERICAN SAMOA",
  },
  {
    value: "ANDORRA",
    label: "ANDORRA",
  },
  {
    value: "ANGOLA",
    label: "ANGOLA",
  },
  {
    value: "ANGUILLA",
    label: "ANGUILLA",
  },
  {
    value: "ANTARCTICA",
    label: "ANTARCTICA",
  },
  {
    value: "ANTIGUA AND BARBUDA",
    label: "ANTIGUA AND BARBUDA",
  },
  {
    value: "ARGENTINA",
    label: "ARGENTINA",
  },
  {
    value: "ARMENIA",
    label: "ARMENIA",
  },
  {
    value: "ARUBA",
    label: "ARUBA",
  },
  {
    value: "AUSTRALIA",
    label: "AUSTRALIA",
  },
  {
    value: "AUSTRIA",
    label: "AUSTRIA",
  },
  {
    value: "AZERBAIJAN",
    label: "AZERBAIJAN",
  },
  {
    value: "BAHAMAS",
    label: "BAHAMAS",
  },
  {
    value: "BAHRAIN",
    label: "BAHRAIN",
  },
  {
    value: "BANGLADESH",
    label: "BANGLADESH",
  },
  {
    value: "BARBADOS",
    label: "BARBADOS",
  },
  {
    value: "BELARUS",
    label: "BELARUS",
  },
  {
    value: "BELGIUM",
    label: "BELGIUM",
  },
  {
    value: "BELIZE",
    label: "BELIZE",
  },
  {
    value: "BENIN",
    label: "BENIN",
  },
  {
    value: "BERMUDA",
    label: "BERMUDA",
  },
  {
    value: "BHUTAN",
    label: "BHUTAN",
  },
  {
    value: "BOLIVIA (PLURINATIONAL STATE OF)",
    label: "BOLIVIA (PLURINATIONAL STATE OF)",
  },
  {
    value: "BONAIRE, SINT EUSTATIUS AND SABA",
    label: "BONAIRE, SINT EUSTATIUS AND SABA",
  },
  {
    value: "BOSNIA AND HERZEGOVINA",
    label: "BOSNIA AND HERZEGOVINA",
  },
  {
    value: "BOTSWANA",
    label: "BOTSWANA",
  },
  {
    value: "BOUVET ISLAND",
    label: "BOUVET ISLAND",
  },
  {
    value: "BRAZIL",
    label: "BRAZIL",
  },
  {
    value: "BRITISH INDIAN OCEAN TERRITORY",
    label: "BRITISH INDIAN OCEAN TERRITORY",
  },
  {
    value: "UNITED STATES MINOR OUTLYING ISLANDS",
    label: "UNITED STATES MINOR OUTLYING ISLANDS",
  },
  {
    value: "VIRGIN ISLANDS (BRITISH)",
    label: "VIRGIN ISLANDS (BRITISH)",
  },
  {
    value: "VIRGIN ISLANDS (U.S.)",
    label: "VIRGIN ISLANDS (U.S.)",
  },
  {
    value: "BRUNEI DARUSSALAM",
    label: "BRUNEI DARUSSALAM",
  },
  {
    value: "BULGARIA",
    label: "BULGARIA",
  },
  {
    value: "BURKINA FASO",
    label: "BURKINA FASO",
  },
  {
    value: "BURUNDI",
    label: "BURUNDI",
  },
  {
    value: "CAMBODIA",
    label: "CAMBODIA",
  },
  {
    value: "CAMEROON",
    label: "CAMEROON",
  },
  {
    value: "CANADA",
    label: "CANADA",
  },
  {
    value: "CABO VERDE",
    label: "CABO VERDE",
  },
  {
    value: "CAYMAN ISLANDS",
    label: "CAYMAN ISLANDS",
  },
  {
    value: "CENTRAL AFRICAN REPUBLIC",
    label: "CENTRAL AFRICAN REPUBLIC",
  },
  {
    value: "CHAD",
    label: "CHAD",
  },
  {
    value: "CHILE",
    label: "CHILE",
  },
  {
    value: "CHINA",
    label: "CHINA",
  },
  {
    value: "CHRISTMAS ISLAND",
    label: "CHRISTMAS ISLAND",
  },
  {
    value: "COCOS (KEELING) ISLANDS",
    label: "COCOS (KEELING) ISLANDS",
  },
  {
    value: "COLOMBIA",
    label: "COLOMBIA",
  },
  {
    value: "COMOROS",
    label: "COMOROS",
  },
  {
    value: "CONGO",
    label: "CONGO",
  },
  {
    value: "CONGO (DEMOCRATIC REPUBLIC OF THE)",
    label: "CONGO (DEMOCRATIC REPUBLIC OF THE)",
  },
  {
    value: "COOK ISLANDS",
    label: "COOK ISLANDS",
  },
  {
    value: "COSTA RICA",
    label: "COSTA RICA",
  },
  {
    value: "CROATIA",
    label: "CROATIA",
  },
  {
    value: "CUBA",
    label: "CUBA",
  },
  {
    value: "CURAÇAO",
    label: "CURAÇAO",
  },
  {
    value: "CYPRUS",
    label: "CYPRUS",
  },
  {
    value: "CZECH REPUBLIC",
    label: "CZECH REPUBLIC",
  },
  {
    value: "DENMARK",
    label: "DENMARK",
  },
  {
    value: "DJIBOUTI",
    label: "DJIBOUTI",
  },
  {
    value: "DOMINICA",
    label: "DOMINICA",
  },
  {
    value: "DOMINICAN REPUBLIC",
    label: "DOMINICAN REPUBLIC",
  },
  {
    value: "ECUADOR",
    label: "ECUADOR",
  },
  {
    value: "EGYPT",
    label: "EGYPT",
  },
  {
    value: "EL SALVADOR",
    label: "EL SALVADOR",
  },
  {
    value: "EQUATORIAL GUINEA",
    label: "EQUATORIAL GUINEA",
  },
  {
    value: "ERITREA",
    label: "ERITREA",
  },
  {
    value: "ESTONIA",
    label: "ESTONIA",
  },
  {
    value: "ETHIOPIA",
    label: "ETHIOPIA",
  },
  {
    value: "FALKLAND ISLANDS (MALVINAS)",
    label: "FALKLAND ISLANDS (MALVINAS)",
  },
  {
    value: "FAROE ISLANDS",
    label: "FAROE ISLANDS",
  },
  {
    value: "FIJI",
    label: "FIJI",
  },
  {
    value: "FINLAND",
    label: "FINLAND",
  },
  {
    value: "FRANCE",
    label: "FRANCE",
  },
  {
    value: "FRENCH GUIANA",
    label: "FRENCH GUIANA",
  },
  {
    value: "FRENCH POLYNESIA",
    label: "FRENCH POLYNESIA",
  },
  {
    value: "FRENCH SOUTHERN TERRITORIES",
    label: "FRENCH SOUTHERN TERRITORIES",
  },
  {
    value: "GABON",
    label: "GABON",
  },
  {
    value: "GAMBIA",
    label: "GAMBIA",
  },
  {
    value: "GEORGIA",
    label: "GEORGIA",
  },
  {
    value: "GERMANY",
    label: "GERMANY",
  },
  {
    value: "GHANA",
    label: "GHANA",
  },
  {
    value: "GIBRALTAR",
    label: "GIBRALTAR",
  },
  {
    value: "GREECE",
    label: "GREECE",
  },
  {
    value: "GREENLAND",
    label: "GREENLAND",
  },
  {
    value: "GRENADA",
    label: "GRENADA",
  },
  {
    value: "GUADELOUPE",
    label: "GUADELOUPE",
  },
  {
    value: "GUAM",
    label: "GUAM",
  },
  {
    value: "GUATEMALA",
    label: "GUATEMALA",
  },
  {
    value: "GUERNSEY",
    label: "GUERNSEY",
  },
  {
    value: "GUINEA",
    label: "GUINEA",
  },
  {
    value: "GUINEA-BISSAU",
    label: "GUINEA-BISSAU",
  },
  {
    value: "GUYANA",
    label: "GUYANA",
  },
  {
    value: "HAITI",
    label: "HAITI",
  },
  {
    value: "HEARD ISLAND AND MCDONALD ISLANDS",
    label: "HEARD ISLAND AND MCDONALD ISLANDS",
  },
  {
    value: "HOLY SEE",
    label: "HOLY SEE",
  },
  {
    value: "HONDURAS",
    label: "HONDURAS",
  },
  {
    value: "HONG KONG",
    label: "HONG KONG",
  },
  {
    value: "HUNGARY",
    label: "HUNGARY",
  },
  {
    value: "ICELAND",
    label: "ICELAND",
  },
  {
    value: "INDIA",
    label: "INDIA",
  },
  {
    value: "INDONESIA",
    label: "INDONESIA",
  },
  {
    value: "CÔTE D'IVOIRE",
    label: "CÔTE D'IVOIRE",
  },
  {
    value: "IRAN (ISLAMIC REPUBLIC OF)",
    label: "IRAN (ISLAMIC REPUBLIC OF)",
  },
  {
    value: "IRAQ",
    label: "IRAQ",
  },
  {
    value: "IRELAND",
    label: "IRELAND",
  },
  {
    value: "ISLE OF MAN",
    label: "ISLE OF MAN",
  },
  {
    value: "ISRAEL",
    label: "ISRAEL",
  },
  {
    value: "ITALY",
    label: "ITALY",
  },
  {
    value: "JAMAICA",
    label: "JAMAICA",
  },
  {
    value: "JAPAN",
    label: "JAPAN",
  },
  {
    value: "JERSEY",
    label: "JERSEY",
  },
  {
    value: "JORDAN",
    label: "JORDAN",
  },
  {
    value: "KAZAKHSTAN",
    label: "KAZAKHSTAN",
  },
  {
    value: "KENYA",
    label: "KENYA",
  },
  {
    value: "KIRIBATI",
    label: "KIRIBATI",
  },
  {
    value: "KUWAIT",
    label: "KUWAIT",
  },
  {
    value: "KYRGYZSTAN",
    label: "KYRGYZSTAN",
  },
  {
    value: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    label: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    value: "LATVIA",
    label: "LATVIA",
  },
  {
    value: "LEBANON",
    label: "LEBANON",
  },
  {
    value: "LESOTHO",
    label: "LESOTHO",
  },
  {
    value: "LIBERIA",
    label: "LIBERIA",
  },
  {
    value: "LIBYA",
    label: "LIBYA",
  },
  {
    value: "LIECHTENSTEIN",
    label: "LIECHTENSTEIN",
  },
  {
    value: "LITHUANIA",
    label: "LITHUANIA",
  },
  {
    value: "LUXEMBOURG",
    label: "LUXEMBOURG",
  },
  {
    value: "MACAO",
    label: "MACAO",
  },
  {
    value: "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)",
    label: "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)",
  },
  {
    value: "MADAGASCAR",
    label: "MADAGASCAR",
  },
  {
    value: "MALAWI",
    label: "MALAWI",
  },
  {
    value: "MALAYSIA",
    label: "MALAYSIA",
  },
  {
    value: "MALDIVES",
    label: "MALDIVES",
  },
  {
    value: "MALI",
    label: "MALI",
  },
  {
    value: "MALTA",
    label: "MALTA",
  },
  {
    value: "MARSHALL ISLANDS",
    label: "MARSHALL ISLANDS",
  },
  {
    value: "MARTINIQUE",
    label: "MARTINIQUE",
  },
  {
    value: "MAURITANIA",
    label: "MAURITANIA",
  },
  {
    value: "MAURITIUS",
    label: "MAURITIUS",
  },
  {
    value: "MAYOTTE",
    label: "MAYOTTE",
  },
  {
    value: "MEXICO",
    label: "MEXICO",
  },
  {
    value: "MICRONESIA (FEDERATED STATES OF)",
    label: "MICRONESIA (FEDERATED STATES OF)",
  },
  {
    value: "MOLDOVA (REPUBLIC OF)",
    label: "MOLDOVA (REPUBLIC OF)",
  },
  {
    value: "MONACO",
    label: "MONACO",
  },
  {
    value: "MONGOLIA",
    label: "MONGOLIA",
  },
  {
    value: "MONTENEGRO",
    label: "MONTENEGRO",
  },
  {
    value: "MONTSERRAT",
    label: "MONTSERRAT",
  },
  {
    value: "MOROCCO",
    label: "MOROCCO",
  },
  {
    value: "MOZAMBIQUE",
    label: "MOZAMBIQUE",
  },
  {
    value: "MYANMAR",
    label: "MYANMAR",
  },
  {
    value: "NAMIBIA",
    label: "NAMIBIA",
  },
  {
    value: "NAURU",
    label: "NAURU",
  },
  {
    value: "NEPAL",
    label: "NEPAL",
  },
  {
    value: "NETHERLANDS",
    label: "NETHERLANDS",
  },
  {
    value: "NEW CALEDONIA",
    label: "NEW CALEDONIA",
  },
  {
    value: "NEW ZEALAND",
    label: "NEW ZEALAND",
  },
  {
    value: "NICARAGUA",
    label: "NICARAGUA",
  },
  {
    value: "NIGER",
    label: "NIGER",
  },
  {
    value: "NIGERIA",
    label: "NIGERIA",
  },
  {
    value: "NIUE",
    label: "NIUE",
  },
  {
    value: "NORFOLK ISLAND",
    label: "NORFOLK ISLAND",
  },
  {
    value: "KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)",
    label: "KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)",
  },
  {
    value: "NORTHERN MARIANA ISLANDS",
    label: "NORTHERN MARIANA ISLANDS",
  },
  {
    value: "NORWAY",
    label: "NORWAY",
  },
  {
    value: "OMAN",
    label: "OMAN",
  },
  {
    value: "PAKISTAN",
    label: "PAKISTAN",
  },
  {
    value: "PALAU",
    label: "PALAU",
  },
  {
    value: "PALESTINE, STATE OF",
    label: "PALESTINE, STATE OF",
  },
  {
    value: "PANAMA",
    label: "PANAMA",
  },
  {
    value: "PAPUA NEW GUINEA",
    label: "PAPUA NEW GUINEA",
  },
  {
    value: "PARAGUAY",
    label: "PARAGUAY",
  },
  {
    value: "PERU",
    label: "PERU",
  },
  {
    value: "PHILIPPINES",
    label: "PHILIPPINES",
  },
  {
    value: "PITCAIRN",
    label: "PITCAIRN",
  },
  {
    value: "POLAND",
    label: "POLAND",
  },
  {
    value: "PORTUGAL",
    label: "PORTUGAL",
  },
  {
    value: "PUERTO RICO",
    label: "PUERTO RICO",
  },
  {
    value: "QATAR",
    label: "QATAR",
  },
  {
    value: "REPUBLIC OF KOSOVO",
    label: "REPUBLIC OF KOSOVO",
  },
  {
    value: "RÉUNION",
    label: "RÉUNION",
  },
  {
    value: "ROMANIA",
    label: "ROMANIA",
  },
  {
    value: "RUSSIAN FEDERATION",
    label: "RUSSIAN FEDERATION",
  },
  {
    value: "RWANDA",
    label: "RWANDA",
  },
  {
    value: "SAINT BARTHÉLEMY",
    label: "SAINT BARTHÉLEMY",
  },
  {
    value: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    label: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
  },
  {
    value: "SAINT KITTS AND NEVIS",
    label: "SAINT KITTS AND NEVIS",
  },
  {
    value: "SAINT LUCIA",
    label: "SAINT LUCIA",
  },
  {
    value: "SAINT MARTIN (FRENCH PART)",
    label: "SAINT MARTIN (FRENCH PART)",
  },
  {
    value: "SAINT PIERRE AND MIQUELON",
    label: "SAINT PIERRE AND MIQUELON",
  },
  {
    value: "SAINT VINCENT AND THE GRENADINES",
    label: "SAINT VINCENT AND THE GRENADINES",
  },
  {
    value: "SAMOA",
    label: "SAMOA",
  },
  {
    value: "SAN MARINO",
    label: "SAN MARINO",
  },
  {
    value: "SAO TOME AND PRINCIPE",
    label: "SAO TOME AND PRINCIPE",
  },
  {
    value: "SAUDI ARABIA",
    label: "SAUDI ARABIA",
  },
  {
    value: "SENEGAL",
    label: "SENEGAL",
  },
  {
    value: "SERBIA",
    label: "SERBIA",
  },
  {
    value: "SEYCHELLES",
    label: "SEYCHELLES",
  },
  {
    value: "SIERRA LEONE",
    label: "SIERRA LEONE",
  },
  {
    value: "SINGAPORE",
    label: "SINGAPORE",
  },
  {
    value: "SINT MAARTEN (DUTCH PART)",
    label: "SINT MAARTEN (DUTCH PART)",
  },
  {
    value: "SLOVAKIA",
    label: "SLOVAKIA",
  },
  {
    value: "SLOVENIA",
    label: "SLOVENIA",
  },
  {
    value: "SOLOMON ISLANDS",
    label: "SOLOMON ISLANDS",
  },
  {
    value: "SOMALIA",
    label: "SOMALIA",
  },
  {
    value: "SOUTH AFRICA",
    label: "SOUTH AFRICA",
  },
  {
    value: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
  },
  {
    value: "KOREA (REPUBLIC OF)",
    label: "KOREA (REPUBLIC OF)",
  },
  {
    value: "SOUTH SUDAN",
    label: "SOUTH SUDAN",
  },
  {
    value: "SPAIN",
    label: "SPAIN",
  },
  {
    value: "SRI LANKA",
    label: "SRI LANKA",
  },
  {
    value: "SUDAN",
    label: "SUDAN",
  },
  {
    value: "SURINAME",
    label: "SURINAME",
  },
  {
    value: "SVALBARD AND JAN MAYEN",
    label: "SVALBARD AND JAN MAYEN",
  },
  {
    value: "SWAZILAND",
    label: "SWAZILAND",
  },
  {
    value: "SWEDEN",
    label: "SWEDEN",
  },
  {
    value: "SWITZERLAND",
    label: "SWITZERLAND",
  },
  {
    value: "SYRIAN ARAB REPUBLIC",
    label: "SYRIAN ARAB REPUBLIC",
  },
  {
    value: "TAIWAN",
    label: "TAIWAN",
  },
  {
    value: "TAJIKISTAN",
    label: "TAJIKISTAN",
  },
  {
    value: "TANZANIA, UNITED REPUBLIC OF",
    label: "TANZANIA, UNITED REPUBLIC OF",
  },
  {
    value: "THAILAND",
    label: "THAILAND",
  },
  {
    value: "TIMOR-LESTE",
    label: "TIMOR-LESTE",
  },
  {
    value: "TOGO",
    label: "TOGO",
  },
  {
    value: "TOKELAU",
    label: "TOKELAU",
  },
  {
    value: "TONGA",
    label: "TONGA",
  },
  {
    value: "TRINIDAD AND TOBAGO",
    label: "TRINIDAD AND TOBAGO",
  },
  {
    value: "TUNISIA",
    label: "TUNISIA",
  },
  {
    value: "TURKEY",
    label: "TURKEY",
  },
  {
    value: "TURKMENISTAN",
    label: "TURKMENISTAN",
  },
  {
    value: "TURKS AND CAICOS ISLANDS",
    label: "TURKS AND CAICOS ISLANDS",
  },
  {
    value: "TUVALU",
    label: "TUVALU",
  },
  {
    value: "UGANDA",
    label: "UGANDA",
  },
  {
    value: "UKRAINE",
    label: "UKRAINE",
  },
  {
    value: "UNITED ARAB EMIRATES",
    label: "UNITED ARAB EMIRATES",
  },
  {
    value: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
    label: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
  },
  {
    value: "URUGUAY",
    label: "URUGUAY",
  },
  {
    value: "UZBEKISTAN",
    label: "UZBEKISTAN",
  },
  {
    value: "VANUATU",
    label: "VANUATU",
  },
  {
    value: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    label: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
  },
  {
    value: "VIET NAM",
    label: "VIET NAM",
  },
  {
    value: "WALLIS AND FUTUNA",
    label: "WALLIS AND FUTUNA",
  },
  {
    value: "WESTERN SAHARA",
    label: "WESTERN SAHARA",
  },
  {
    value: "YEMEN",
    label: "YEMEN",
  },
  {
    value: "ZAMBIA",
    label: "ZAMBIA",
  },
  {
    value: "ZIMBABWE",
    label: "ZIMBABWE",
  },
];
