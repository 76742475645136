import { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import axiosInstance from "../shared/axiosInstance";

export const usePostService = () => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const postData = async (url: string, payload: any, method?: string) => {
    setIsSubmitting(true);
    try {
      const result = await axiosInstance.request({
        method: method ? method : "POST",
        url: process.env.REACT_APP_BASE_URL + "/" + url,
        data: payload,
      });
      setResponse(result.data);
      return result;
    } catch (err: any) {
      setError(err?.message);
      setIsSubmitting(false);
      if (axios.isAxiosError(err)) {
        // Handle Axios-specific errors
        console.error("Axios error:", err.response?.data?.message);
        throw err.response?.data?.message;
      } else {
        // Handle general errors
        console.error("General error:", err.message);
        throw err.message;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return { response, error, isSubmitting, postData };
};
