// Third party
import FadeLoader from "react-spinners/FadeLoader";

// Internal
import "./LoadingOverlay.scss";

type LoadingOverlayProps = {
  loadingText?: string;
};

export const LoadingOverlay = ({ loadingText }: LoadingOverlayProps) => {
  return (
    <div className="loading-overlay-container">
      <div className="loading-content">
        <div className="loader-sec">
          <FadeLoader className="fade-loader" />
        </div>
        {loadingText && <div>{loadingText}</div>}
      </div>
    </div>
  );
};
