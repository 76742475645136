import { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useAuthService = (url: string) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const auth = async (payload: any) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/${url}`,
        payload,
        {
          withCredentials: true,
          headers: {
            accept: "*/*",
          },
        }
      );
      sessionStorage.setItem("token", response.data.token);
      setResponse(response.data);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.message);
      setIsLoading(false);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logOut = async (
    postLogoutRedirectUrl: string = "/",
    message: string = ""
  ) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}`,
        {},
        {
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error("Failed to end server session:", err);
    } finally {
      sessionStorage.removeItem("token");
      toast.info(message || "Logged out successfully");
      navigate(postLogoutRedirectUrl);
    }
  };

  return { response, error, isLoading, auth, logOut };
};
