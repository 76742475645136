// project imports
import { MainLayout } from "../../layout";
import { useScopedRoutes } from "../../hooks/useScopedRoutes";

// ==============================|| MAIN ROUTING ||============================== //

export const MainRoutes = () => {
  const { scopedRoutes } = useScopedRoutes();

  return {
    path: "/",
    element: <MainLayout />,
    children: scopedRoutes || [],
  };
};
