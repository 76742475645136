import Select, { Props } from "react-select";

// Internal
import "./SelectDropdown.scss";

type SelectDropdownProps = {
  containerClassName?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
};

export const SelectDropdown = ({
  containerClassName,
  label,
  placeholder,
  required,
  ...rest
}: Props & SelectDropdownProps) => {
  return (
    <div className={`select-box-container ${containerClassName}`}>
      {label && (
        <label className="label-style">
          {label} {required && <span className="mandatory-field">*</span>}
        </label>
      )}
      <Select className="label-style" {...rest} placeholder={placeholder} />
    </div>
  );
};
