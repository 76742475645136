export enum UserRole {
  VENDOR = "vendor",
  SUPER_ADMIN = "superAdmin",
  CARNVAL_ADMIN = "admin",
}

export enum VendorTypeService {
  HOTEL_ONLY = "Hotel",
  TRANSPORTATION_ONLY = "Transportation",
  PORT_AGENT = "Port Agent",
  SAFEGUARD = "Safeguard",
  CBP = "CBP",
  AGENCY_MANILA = "Agency Manila",
  AGENCY_INDONESIA = "Agency Indonesia",
}
