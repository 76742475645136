import { BrowserRouter as Router } from "react-router-dom";

//Third party
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Internal
import { ThemeRoutes } from "./routes";
import { GlobalContext } from "./context";
import "./App.scss";

// Polyfill for Object.groupBy. [https://algoright.sentry.io/issues/514459]
if (typeof (Object as any).groupBy === typeof undefined) {
  (Object as any).groupBy = (arr: any, callback: any) => {
    return arr.reduce((acc: any = {}, ...args: any) => {
      const key = callback(...args);
      acc[key] ??= [];
      acc[key].push(args[0]);
      return acc;
    }, {});
  };
}

function App() {
  return (
    <GlobalContext>
      <div className="app-container">
        <ToastContainer />
        <Router>
          <ThemeRoutes />
        </Router>
      </div>
    </GlobalContext>
  );
}

export default App;
