import { Button as MuiButton, ButtonProps } from "@mui/material";

// Internal import
import "./MGButton.scss";

export const MGButton = ({
  className,
  variant = "contained",
  children,
  ...rest
}: ButtonProps) => {
  return (
    <MuiButton
      variant={variant}
      className={`button-container ${className ? className : ""}`}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
