import { useEffect, useState } from "react";
import { MGButton } from "../../../components";
import SVGUser from "../../../resources/svg/user.icon";
import "./RegisterPasskey.scss";
import {
  browserSupportsWebAuthn,
  startRegistration,
} from "@simplewebauthn/browser";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserData } from "../../../shared/utils";
import { RoutePath } from "../../../routes";

const RegisterPassKey = () => {
  const [passKeyAuthSupported, setPassKeyAuthSupported] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    setPassKeyAuthSupported(browserSupportsWebAuthn());
  }, []);

  const handlePasskeyRegister = async () => {
    try {
      const userData = getUserData();
      if (!userData?.email) {
        throw new Error("User not found");
      }
      const baseUrl = `${process.env.REACT_APP_AUTH_SERVER as string}/auth`;
      const resp = await fetch(baseUrl + "/generate-registration-options", {
        method: "POST",
        credentials: "include",
        // mode: "cors",
        body: JSON.stringify({ userId: userData.email, claims: userData }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const opts = await resp.json();
      const attResp = await startRegistration({
        ...opts,
      });

      const verificationResp = await fetch(baseUrl + "/verify-registration", {
        method: "POST",
        credentials: "include",
        // mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(attResp),
      });

      const verificationJSON = await verificationResp.json();

      if (verificationJSON && verificationJSON.verified) {
        console.log("Token Passkey:", verificationJSON);
        if (state?.from) {
          navigate(RoutePath.SETTINGS);
        } else {
          navigate("/dashboard", { replace: true });
        }
        toast.success("Passkey registered successfully");
      } else {
      }
    } catch (error: any) {
      console.log(error);
      if (error?.name !== "NotAllowedError") {
        alert("Registration error: " + error);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", background: "white" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1720170494675-e2dcd6de34a7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{ width: "50vw", height: "100vh" }}
        />
        <div
          style={{ width: "50vw", display: "flex", justifyContent: "center" }}
        >
          <div
            className="shadow"
            style={{
              width: "40vw",
              padding: "2rem",
              borderRadius: "5px",
              paddingTop: "5rem",
              paddingBottom: "5rem",
            }}
          >
            {passKeyAuthSupported ? (
              <div>
                <SVGUser />
                <h2>Sign in faster with passkeys</h2>
                <p>
                  Passkeys are a great way to securely sign in to your account.
                  You can use face recognition or fingerprints to sign in.
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2rem",
                    gap: "1rem",
                  }}
                >
                  {state?.from !== "settings" && (
                    <p
                      onClick={() => navigate(RoutePath.DASHBOARD)}
                      style={{ cursor: "pointer" }}
                    >
                      May be later
                    </p>
                  )}
                  {state?.from === "settings" && (
                    <p
                      onClick={() => navigate(RoutePath.SETTINGS)}
                      style={{ cursor: "pointer" }}
                    >
                      Go back
                    </p>
                  )}
                  <MGButton onClick={handlePasskeyRegister}>
                    Let's go &rarr;
                  </MGButton>
                </div>
              </div>
            ) : (
              <div>
                <h2>Sorry! </h2>
                <p>
                  Passkeys are not supported in your browser, Please try with
                  different modern browser...
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPassKey;
