import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// Import react Icon
import { AiOutlineRight } from "react-icons/ai";

// Scoped routes
import { ScopeRouteType } from "../../routes/MainRoutes";

// internal
import { ActionTypes, GlobalContextData } from "../../context";

// Style
import "./LeftNavBar.scss";
import { useScopedRoutes } from "../../hooks";
import { getUserData } from "../../shared/utils";
import { UserRole, VendorTypeService } from "../../constants";
import SVGMessage from "../../resources/temp/message";

const messages = [
  "Flight Emirates EK449 is delayed by 2 hours. Crew 110023 and 012399 are impacted and may check in later than expected",
];

export const LeftNavBar = () => {
  const { leftMenuScopedRoutes } = useScopedRoutes();
  const isVendor = getUserData()?.["custom:roleName"] === UserRole.VENDOR;
  const isHotelVendor =
    getUserData()?.["custom:vendorType"] === VendorTypeService.HOTEL_ONLY;

  // console.log("leftMenuScopedRoutes", leftMenuScopedRoutes);

  const { state, dispatch } = useContext(GlobalContextData);

  const location = useLocation();
  const pathName = location.pathname;

  return (
    <nav className={`sidebar ${state.openSidebar ? "" : "close"}`}>
      <header>
        <div
          className="toggle"
          onClick={() =>
            dispatch({
              type: ActionTypes.OPEN_CLOSE_SIDE_BAR,
              payload: !state.openSidebar,
            })
          }
        >
          <AiOutlineRight />
        </div>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            {leftMenuScopedRoutes &&
              Object.keys(leftMenuScopedRoutes).map(
                (groupKey: string, index) => {
                  return (
                    <div key={groupKey + "" + index} className="content-height">
                      {index > 0 && <hr className="horizontal-line" />}
                      {state.openSidebar && groupKey && (
                        <div className="menu-group">{groupKey}</div>
                      )}
                      {leftMenuScopedRoutes[groupKey].map(
                        (scopeRoute: ScopeRouteType) => {
                          return (
                            <li
                              className={`nav-link ${
                                scopeRoute.notvisible !== undefined &&
                                scopeRoute.notvisible ===
                                  getUserData()?.["custom:vendorType"]
                                  ? "hide"
                                  : ""
                              }`}
                              key={scopeRoute.path}
                            >
                              <Link
                                to={scopeRoute.path}
                                className={`left-section-text-color ${
                                  pathName === scopeRoute.path
                                    ? "selected-link"
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    pathName === scopeRoute.path
                                      ? "selected-icon"
                                      : "icon"
                                  }
                                >
                                  {scopeRoute.icon ? scopeRoute.icon : ""}
                                </span>
                                <span className="text nav-text">
                                  {scopeRoute.label ? scopeRoute.label : ""}
                                </span>
                                {scopeRoute.count && (
                                  <span className="text count-text">
                                    {state.vendorRequestCount ||
                                      scopeRoute.count}
                                  </span>
                                )}
                              </Link>
                            </li>
                          );
                        }
                      )}
                    </div>
                  );
                }
              )}
          </ul>

          {isVendor && messages.length && state.openSidebar && (
            <div>
              <hr className="horizontal-line" />
              <div className="message-group">
                <div>
                  <SVGMessage />
                </div>
                <div menu-text>MESSAGES</div>
              </div>
            </div>
          )}

          <div className="sidebar-msg-container">
            {isVendor &&
              state.openSidebar &&
              messages.length > 0 &&
              messages.map((message, index) => {
                return (
                  <>
                    <div className="sidebar-msg-text" key={message}>
                      {" "}
                      {message}
                    </div>
                  </>
                );
              })}
          </div>
          {isVendor && state.openSidebar && (
            <div className="sidebar-msg-text last-msg">
              Crew 497901’s assignment was cancelled and he will not be checking
              in tomorrow.
            </div>
          )}
          {/* <hr className="sidebar-horizontal-line" />
          <div
            className={`${
              state.openSidebar ? "sidebar-bottom" : "close-sidebar-bottom"
            }`}
          >
            <p className="title-top">TITLE HOLDER</p>
            <div className="bottom-container">
              <div className="bottom-left">
                <div className="icon-left"></div>
                {state.openSidebar && <div>Updates</div>}
              </div>
              <div className="right-number-container">120</div>
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};
