import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Alert, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";

// Internal
import { useDisplayVendorDetails } from "../../hooks";
import { ITab } from "./VendorInfoTypes";
import { RoutePath } from "../../routes";

// Style
import "./VendorProfileDetails.scss";

type HotelDetailsType = {
  children: React.ReactNode;
};

export const VendorProfileDetails = (props: HotelDetailsType) => {
  const { tabs, tab, handleTabChange, isLoading, error } =
    useDisplayVendorDetails();

  return (
    <section className="vendor-details-container">
      {!isLoading && error && (
        <Alert severity="error">
          Some thing went wrong while fetching vendor info!
        </Alert>
      )}
      <Link className="link-css" to={RoutePath.DASHBOARD}>
        <ArrowBackIosIcon className="back-icon" />
        Dashboard
      </Link>
      <div className="tabs-back-buttons">
        <div className="tabs-data">
          <Tabs
            value={tab.value}
            aria-label="lab API tabs example"
            onChange={(_undefined, value) => handleTabChange(value)}
          >
            {tabs.map((tabData: ITab, index: number) => {
              return (
                <Tab
                  key={`${index}${tabData.value}`}
                  value={tabData.value}
                  label={
                    <div className="tab-label">
                      <CheckCircleIcon
                        className={
                          tabData.completeStatus ? "completed" : "not-completed"
                        }
                      />
                      <span>{tabData.label}</span>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </div>
      </div>

      <div className="form-section">{props.children}</div>
    </section>
  );
};
