import axios, { AxiosError } from "axios";
import { API } from "../constants";
import { getUserData } from "./utils";

let isTokenRefreshing = false;
let tokenRefreshPromise: Promise<any> | null = null;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("token");
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAuthToken = async () => {
  try {
    const userDetails = getUserData();
    const userId = userDetails?.["cognito:username"];
    if (!userId) {
      return Promise.reject("User not logged in");
    }
    const refreshResult = await axiosInstance.post(
      `/${API.AUTH.REFRESH_AUTH_TOKENS}`,
      { userId },
      {
        withCredentials: true,
      }
    );
    return refreshResult;
  } catch (refreshErr) {
    return Promise.reject(refreshErr);
  }
};

// Interceptor to retry failed requests if token is expired
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (
      error.response &&
      error.response.status === 403 &&
      error.config &&
      error.response?.data &&
      typeof error.response.data === "object" &&
      (error.response.data as any).message === "Failed to authenticate token."
    ) {
      try {
        const latestAuthToken = sessionStorage.getItem("token");

        if (
          latestAuthToken &&
          latestAuthToken !== error.config.headers.Authorization
        ) {
          error.config.headers.Authorization = latestAuthToken;
          return axiosInstance(error.config);
        }

        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          tokenRefreshPromise = refreshAuthToken();
        }

        const refreshResult = await tokenRefreshPromise;

        if (refreshResult.data.IdToken) {
          sessionStorage.setItem("token", refreshResult.data.IdToken);
          error.config.headers.Authorization = refreshResult.data.IdToken;
          return axiosInstance(error.config); // retrying the original request with new token
        }
      } catch (refreshErr) {
        return Promise.reject(refreshErr);
      } finally {
        isTokenRefreshing = false;
        tokenRefreshPromise = null;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
