import { Pagination } from "@mui/material";

type GridPaginationProps = {
  count: number;
  page: number;
  showOnPage: number;
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  handleSelectShowOnPage?: (event: any) => void;
};

const opt = [5, 10, 15, 20, 25];
export const GridPagination = ({
  showOnPage,
  count,
  page,
  onChange,
  handleSelectShowOnPage,
}: GridPaginationProps) => {
  return (
    <div className="pagination-section">
      <div>
        <select
          value={showOnPage}
          onChange={handleSelectShowOnPage}
          className="page-size-field "
        >
          {opt.map((x) => {
            return (
              <option key={x} value={x}>
                {x} rows
              </option>
            );
          })}
        </select>
        <span className="page-text">Show on page</span>
      </div>
      <div>
        <Pagination
          count={count}
          page={page}
          shape="rounded"
          className="page-count-size"
          onChange={onChange}
        />
      </div>
    </div>
  );
};
