//Internal imports
import "./Card.scss";

export type ICardProps = {
  id: string;
  description: string;
  name: string;
  icon?: any;
  className?: string;
  onClick?: () => void;
  activeId?: string;
};

export const Card = ({
  name,
  icon,
  className,
  onClick,
  activeId,
  id,
}: ICardProps) => {
  return (
    <>
      <div
        className={`card-container ${
          activeId === id ? "active-card" : ""
        } ${className}`}
        onClick={onClick}
      >
        <div className="card-title">{name}</div>
        <div className="card-image">{icon}</div>
      </div>
    </>
  );
};
