import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";

// Internal
import { ActionTypes, GlobalContextData } from "../context";
import { useDocumentTitle } from "./useDocumentTitle";
import { API, VendorTypeService } from "../constants";
import { ITab } from "../pages/VendorProfileDetails/VendorInfoTypes";
import { useGetService } from "./useGetService";
import { RoutePath } from "../routes";
import { getUserData } from "../shared/utils";

const hotelTabsData: ITab[] = [
  {
    id: "profile",
    label: "Profile details",
    value: 0,
    completeStatus: false,
  },
  {
    id: "contact-information",
    label: "Contacts",
    value: 1,
    completeStatus: false,
  },
  {
    id: "room-projections",
    label: "Room Projections",
    value: 2,
    completeStatus: false,
  },
  {
    id: "meal-allowance",
    label: "Meal Allowance",
    value: 3,
    completeStatus: false,
  },
  {
    id: "amenities",
    label: "Amenities",
    value: 4,
    completeStatus: false,
  },
  {
    id: "employees",
    label: "Employees",
    value: 5,
    completeStatus: false,
  },
];

const transportTabsData: ITab[] = [
  {
    id: "profile",
    label: "Profile details",
    value: 0,
    completeStatus: false,
  },
  {
    id: "contract-informatiom",
    label: "Contract Informatiom",
    value: 1,
    completeStatus: false,
  },
  {
    id: "amenities",
    label: "Amenities",
    value: 2,
    completeStatus: false,
  },
  {
    id: "employees",
    label: "Employees",
    value: 3,
    completeStatus: false,
  },
  {
    id: "vehicles",
    label: "Vehicles",
    value: 4,
    completeStatus: false,
  },
];

export const useDisplayVendorDetails = () => {
  const vendorType = getUserData()["custom:vendorType"];
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Global store using context
  const { state, dispatch } = useContext(GlobalContextData);
  //const vendorType = state?.userInfo?.vendorType;

  useDocumentTitle(
    vendorType === VendorTypeService.HOTEL_ONLY ? "Hotel" : "Transportation"
  );

  const url =
    vendorType === VendorTypeService.HOTEL_ONLY
      ? `${API.HOTEL.GET_HOTEL_DETAILS_BY_VENDORID}/${id}`
      : `${API.TRANSPORT.GET_TRANSPORT_DETAILS_BY_VENDORID}/${id}`;

  const tabsData = _.cloneDeep(
    vendorType === VendorTypeService.HOTEL_ONLY
      ? hotelTabsData
      : transportTabsData
  );

  const { response, isLoading, error } = useGetService(id && url);

  console.log("useDisplayVendorDetails", state, response);
  const [tabs, setTabs] = useState(tabsData);

  const [tab, setTab] = useState<ITab>(tabs[0]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: {
        isLoading: isLoading,
        loadingText: "Please wait...",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (
      vendorType === VendorTypeService.HOTEL_ONLY &&
      response &&
      Object.keys(response).length > 0
    ) {
      dispatch({
        type: ActionTypes.SET_HOTEL_INFO,
        payload: response,
      });
    }

    if (
      vendorType === VendorTypeService.TRANSPORTATION_ONLY &&
      response &&
      Object.keys(response).length > 0
    ) {
      dispatch({
        type: ActionTypes.SET_TRANSPORT_INFO,
        payload: response,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, vendorType]);

  useEffect(() => {
    const data = [...tabs];
    if (vendorType === VendorTypeService.HOTEL_ONLY) {
      if (state?.hotelInfo.hotelDetails?.id) {
        data[0].completeStatus = true;
      }
      if (state?.hotelInfo?.contacts?.length > 0) {
        data[1].completeStatus = true;
      }

      if (state?.hotelInfo?.rooms?.length > 0) {
        data[2].completeStatus = true;
      }
      if (
        state?.hotelInfo?.mealAllowanceDetails?.mealAllowance.length > 0 &&
        state?.hotelInfo?.mealAllowanceDetails?.hotelCuisine?.id
      ) {
        data[3].completeStatus = true;
      }
      if (
        state?.hotelInfo?.amenities &&
        state?.hotelInfo?.amenities?.length > 0
      ) {
        data[4].completeStatus = true;
      }
      if (
        state?.hotelInfo?.vendorEmployees &&
        state?.hotelInfo?.vendorEmployees.items &&
        state?.hotelInfo?.vendorEmployees.items.length > 0
      ) {
        data[5].completeStatus = true;
      }
    }

    if (vendorType === VendorTypeService.TRANSPORTATION_ONLY) {
      if (state?.transportInfo?.transportDetails?.id) {
        data[0].completeStatus = true;
      }
      if (state?.transportInfo?.contract?.typeOfServices?.length > 0) {
        data[1].completeStatus = true;
      }
      if (
        state?.transportInfo?.amenities &&
        state?.transportInfo?.amenities.length > 0
      ) {
        data[2].completeStatus = true;
      }
      if (
        state?.transportInfo?.vendorEmployees &&
        state?.transportInfo?.vendorEmployees?.items?.length > 0
      ) {
        data[3].completeStatus = true;
      }

      if (
        state?.transportInfo?.vehicles &&
        state?.transportInfo?.vehicles?.length > 0
      ) {
        data[4].completeStatus = true;
      }
    }

    setTabs(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionTypes.SET_HOTEL_INFO,
        payload: {
          hotelDetails: {},
          contacts: [],
          mealAllowance: [],
          rooms: [],
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tabInfo = tabs.find(
      (tabData: ITab) => tabData.id === location.pathname.split("/")[2]
    );
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: true,
    });
    tabInfo && setTab(tabInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleTabChange = (clickedTab: number) => {
    const tabInfo = tabs.find((tabData: ITab) => tabData.value === clickedTab);
    tabInfo && setTab(tabInfo);
    if (vendorType === VendorTypeService.HOTEL_ONLY) {
      navigate(`/hotel/${tabInfo?.id}/${id}`);
    }

    if (vendorType === VendorTypeService.TRANSPORTATION_ONLY) {
      navigate(`/transport/${tabInfo?.id}/${id}`);
    }
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: false,
    });
    navigate(RoutePath.DASHBOARD);
  };

  return {
    id,
    location,
    navigate,
    handleTabChange,
    handleBack,
    isLoading,
    error,
    tabs,
    tab,
  };
};
