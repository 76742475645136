import { ScopeRouteType, allRoutes } from "../routes";
import { getUserData } from "../shared/utils";

export const useScopedRoutes = () => {
  const role = getUserData() && getUserData()["custom:roleName"];

  // Scoped Route :: Based on role
  const scopedRoutes =
    allRoutes.length > 0
      ? allRoutes.filter((route: ScopeRouteType) => route.scopes.includes(role))
      : [];

  // Display left menu items
  // const leftMenuScopedRoutesFiltered = scopedRoutes.filter(
  //   (route: ScopeRouteType) =>
  //     route.id !== AllRoutesIds.USER_PROFILE &&
  //     route.id !== AllRoutesIds.ADD_HOTEL &&
  //     route.id !== AllRoutesIds.UPDATE_HOTEL
  // );
  const leftMenuScopedRoutesFiltered = scopedRoutes.filter(
    (route: ScopeRouteType) => route.group
  );

  const leftMenuScopedRoutes = (Object as any).groupBy(
    leftMenuScopedRoutesFiltered,
    ({ group }: ScopeRouteType) => group
  );

  return { scopedRoutes, leftMenuScopedRoutes };
};
