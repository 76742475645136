import Modal from "@mui/material/Modal";

// Internal imports
import "./BaseModals.scss";
import { ModalsProps } from "./BaseModals.type";

export const BaseModal = ({
  open,
  onClose,
  onSubmit,
  modalTitle,
  className,
  children,
}: ModalsProps) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className={`modal-container ${className}`}>
            {modalTitle && (
              <>
                <div className="modal-title">
                  <h3 className="title">{modalTitle}</h3>
                </div>
                <hr />
              </>
            )}
            {children}
          </div>
        </>
      </Modal>
    </div>
  );
};
