import React, { useEffect, useRef, useState } from "react";

// internal imports
import "./Popper.scss";

type PopperProps = {
  handleClickOutside: () => void;
  children: React.ReactNode;
  className?: string;
  show: boolean;
};

export const Popper = ({
  handleClickOutside,
  children,
  className,
  show,
}: PopperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideArea, false);
    return () => {
      document.removeEventListener("click", handleClickOutsideArea, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutsideArea = (event: { target: any }) => {
    if (
      wrapperRef.current &&
      !wrapperRef?.current?.contains(event.target as Node)
    ) {
      setIsVisible(false);
      handleClickOutside();
    }
  };

  return (
    <>
      {isVisible && (
        <div ref={wrapperRef} className={`popper-container ${className}`}>
          {children}
        </div>
      )}
    </>
  );
};
