export const DisplayMoreComponent = (items: any) => {
  console.log("DisplayMoreComponent", items);
  return (
    <div className="display-more-component">
      <div className="each-data">
        <span>Destination airport cd</span> :{" "}
        <span>{items.DEST_AIRPORT_CD}</span>
      </div>
    </div>
  );
};
