import { useContext } from "react";
import { Outlet } from "react-router-dom";

// Internal
import "./MainLayout.scss";
import { GlobalContextData } from "../../context";
import { LeftNavBar, LoadingOverlay, TopHeader } from "../../components";

export const MainLayout = () => {
  const { state } = useContext(GlobalContextData);

  return (
    <main className="main-layout-container">
      {state?.loadingState?.isLoading && (
        <LoadingOverlay loadingText={state.loadingState.loadingText || ""} />
      )}
      {!state.hideLeftNavbar && <LeftNavBar />}
      <TopHeader />
      <div className={!state.hideLeftNavbar ? "home" : "without-left-navbar"}>
        <Outlet />
      </div>
    </main>
  );
};
