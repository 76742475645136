import { useEffect, useState } from "react";
import { getUserData } from "../../shared/utils";
import { MGButton } from "../../components";
import { useNavigate } from "react-router-dom";

interface Device {
  counter: number;
  credentialPublicKey: any;
  userId: string;
  aaguid: string;
  transports: string[];
  id: string;
  credentialID: string;
  credentialDeviceType: string;
  credentialType: string;
  deviceDetails: {
    name: string;
    icon_dark: string;
    icon_light: string;
  };
}

const Settings = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDevices() {
      try {
        const userData = getUserData();
        const email = userData?.email;

        if (!email) {
          console.log("Access Denied! Authentication required");
          return;
        }

        const baseUrl = `${process.env.REACT_APP_AUTH_SERVER as string}/user`;
        const resp = await fetch(baseUrl + "/list-devices", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: email }),
          credentials: "include",
        });

        const jsnResp = await resp.json();
        if (jsnResp && jsnResp.items) {
          console.log(jsnResp);
          setDevices(jsnResp.items);
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchDevices();
  }, []);

  const handleDelete = async (deviceId: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_AUTH_SERVER as string}/user`;
      const resp = await fetch(baseUrl + "/delete-device", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ deviceId }),
        credentials: "include",
      });
      const jsnResp = await resp.json();
      if (jsnResp && jsnResp.status === "SUCCESS") {
        console.log(jsnResp);
        setDevices((dev) => dev.filter((d) => d.id !== deviceId));
      } else {
        console.log(jsnResp);
        alert("Something went wrong! Please try again later");
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong! Please try again later");
    }
  };

  return (
    <div style={{ margin: 16, marginLeft: 42, marginRight: 22 }}>
      <div>
        <h1>Registered Passkey Authenticators</h1>
        <div style={{ marginBottom: "1rem" }}>
          <MGButton
            onClick={() =>
              navigate("/register-passkey", {
                state: {
                  from: "settings",
                },
              })
            }
          >
            Register New Passkey
          </MGButton>
        </div>
      </div>
      {devices?.map((device) => (
        <div
          key={device.id}
          style={{
            border: "1px solid #e0e0e0",
            padding: "10px",
            paddingLeft: "16px",
            marginBottom: "10px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.01)",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f9f9f9",
          }}
        >
          <img
            src={device.deviceDetails?.icon_dark}
            alt={device.deviceDetails?.name}
            style={{ width: "50px", height: "50px", marginRight: "10px" }}
          />
          <div style={{ flex: 1 }}>
            <h3>{device.deviceDetails?.name}</h3>
            <p>ID: {device.id}</p>
            <p>Type: {device.credentialDeviceType}</p>
          </div>
          <div style={{ marginRight: 16 }}>
            <MGButton onClick={() => handleDelete(device.id)}>Delete</MGButton>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Settings;
