import { SVGProps } from "react";
const SVGNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={47}
    height={47}
    viewBox="0 0 57 57"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_795"
          data-name="Rectangle 795"
          width={32.05}
          height={35.66}
          fill="#0f192c"
        />
      </clipPath>
    </defs>
    <g
      id="Updates_Notification"
      data-name="Updates/Notification"
      transform="translate(-1677 -5784)"
    >
      <rect
        id="Bottom"
        width={57}
        height={57}
        rx={12}
        transform="translate(1677 5784)"
        fill="transparent"
      />
      <g
        id="Group_3576"
        data-name="Group 3576"
        transform="translate(1689 5795)"
      >
        <g id="Group_3576-2" data-name="Group 3576" clipPath="url(#clip-path)">
          <path
            id="Path_2731"
            data-name="Path 2731"
            d="M31.394,26.306l-2.177-3.132a2.546,2.546,0,0,1-.458-1.452V12.733a12.733,12.733,0,1,0-25.466,0v8.989a2.546,2.546,0,0,1-.458,1.452L.656,26.306a2.547,2.547,0,0,0,1.884,4.253H9.786a6.366,6.366,0,0,0,12.478,0h7.245a2.547,2.547,0,0,0,1.884-4.253Zm-15.369,6.8a3.82,3.82,0,0,1-3.591-2.547h7.181a3.82,3.82,0,0,1-3.591,2.547M2.541,28.012a1.034,1.034,0,0,0,.153-.178l2.228-3.209a5.094,5.094,0,0,0,.917-2.9V12.733a10.186,10.186,0,1,1,20.373,0v8.989a5.093,5.093,0,0,0,.917,2.9l2.228,3.209a1.034,1.034,0,0,0,.153.178Z"
            transform="translate(0)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGNotification;
