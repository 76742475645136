import { useEffect, useState } from "react";

export const useHandleGrid = (enablePagination: boolean, originalRows: any) => {
  const [paginatedData, setPaginatedData] = useState([]);
  const [noOfPageCount, setNoOfPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [showOnPage, setShowOnPage] = useState(5);

  useEffect(() => {
    if (enablePagination && originalRows.length > 0) {
      const allPageCount = Math.ceil(originalRows.length / showOnPage);
      console.log("noOfPageCount", allPageCount, originalRows, showOnPage);

      setNoOfPageCount(allPageCount);
      setPaginatedData(
        handleRowDataBasedOnThePage(0, showOnPage - 1, originalRows)
      );
    }
  }, [originalRows]);

  console.log("noOfPageCount", noOfPageCount);

  useEffect(() => {
    if (enablePagination) {
      const pagePos = (selectedPage - 1) * showOnPage;
      setPaginatedData(
        handleRowDataBasedOnThePage(
          pagePos,
          selectedPage * showOnPage - 1,
          originalRows
        )
      );
    }
  }, [selectedPage, showOnPage]);

  const getUpdatedSelectedPage = (clickedPage: number) => {
    setSelectedPage(clickedPage);
  };

  const updateDataBasedOnshowOnPage = (data: number) => {
    const allPageCount = Math.ceil(originalRows.length / data);
    setNoOfPageCount(allPageCount);
    setShowOnPage(data);
  };

  const handleRowDataBasedOnThePage = (
    pageIndexData: number,
    upToLength: number,
    mainArr: Array<any>
  ) => {
    let temp: any = [];
    for (let i = pageIndexData; i <= upToLength; i++) {
      mainArr[i] !== undefined && temp.push(mainArr[i]);
    }
    return temp;
  };

  return {
    paginatedData,
    noOfPageCount,
    selectedPage,
    showOnPage,
    getUpdatedSelectedPage,
    updateDataBasedOnshowOnPage,
  };
};
