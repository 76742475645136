import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
// Internal
import "./MGDatePicker.scss";

type MGDatePickerProps = {
  containerClassName?: string;
  handlePhoneChange?: (data: string) => void;
  value?: string;
  label?: React.ReactNode;
  required?: boolean;
};

export const MGDatePicker = ({
  containerClassName,
  label,
  required,
  className,
  dateFormat = "dd/MM/yyyy",
  ...rest
}: ReactDatePickerProps & MGDatePickerProps) => {
  return (
    <div className={`date-box-container ${containerClassName}`}>
      {label && (
        <label className="label-style">
          {label} {required && <span className="mandatory-field">*</span>}
        </label>
      )}
      <DatePicker
        showIcon
        className={`base-css ${className}`}
        icon={<CiCalendarDate />}
        dateFormat={dateFormat}
        {...rest}
      />
    </div>
  );
};
