import { ReactNode, useState } from "react";

// Third party
import { AssignmentLate } from "@mui/icons-material";
import { IoIosArrowDown } from "react-icons/io";

// Internal
import "./Grid.scss";
import { SkeltonPlaceholder } from "./SkeltonPlaceholder";
import { GridPagination } from "./GridPagination";
import { useHandleGrid } from "../../hooks";
import { DisplayMoreComponent } from "../../pages/Dashboard/VendorDashboard/DisplayMoreComponent";

interface GridProps {
  columns: columnType[];
  rows: any;
  isLoading: boolean;
  handleIconClick?: (item: any, cell: any, rowIndex: number) => void;
  enableSorting?: boolean;
  sortableKey?: Array<string>;
  enablePagination?: boolean;
}

interface columnType {
  label: string | React.ReactNode;
  id?: number;
  name: string;
}

const TableDisplay = ({ item, column, handleIconClick, rowIndex }: any) => {
  return (
    // @ts-ignore: Unreachable code error
    <td
      data-label={column.name}
      onClick={() =>
        handleIconClick && handleIconClick(item, column.name, rowIndex)
      }
    >
      {column.render ? column.render(item) : item[column.name] || "--"}
    </td>
  );
};

export const Grid = ({
  columns,
  rows,
  isLoading,
  handleIconClick,
  enableSorting = false,
  sortableKey,
  enablePagination = false,
}: GridProps) => {
  const {
    paginatedData,
    noOfPageCount,
    selectedPage,
    getUpdatedSelectedPage,
    showOnPage,
    updateDataBasedOnshowOnPage,
  } = useHandleGrid(enablePagination, rows);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = (columnName: string) => {
    if (!enableSorting) return;
    if (sortColumn === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };

  const prepareRows = (
    rowsData: ReactNode[] | JSX.Element[] | any,
    columns: columnType[]
  ) => {
    const sortData = sortColumn
      ? rowsData.sort((a: any, b: any) => {
          const aValue = (a as any)[sortColumn];
          const bValue = (b as any)[sortColumn];
          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          } else {
            return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
          }
        })
      : rowsData || [];
    return sortData?.map((item: any, index: number) => {
      console.log("item", item);
      return (
        <>
          <tr key={index}>
            {columns?.map((column, columnIndex) => (
              <TableDisplay
                item={item}
                column={column}
                key={columnIndex}
                rowIndex={index}
                handleIconClick={handleIconClick}
              />
            ))}
          </tr>
          {item?.displayMore && <DisplayMoreComponent {...item} />}
        </>
      );
    });
  };

  const handlePageNumberClick = (data: any, value: number) => {
    getUpdatedSelectedPage && getUpdatedSelectedPage(value);
  };

  const handleSelectShowOnPage = (event: any) => {
    updateDataBasedOnshowOnPage(event.target.value);
  };

  return (
    <div className="custom-grid-container">
      <table>
        <thead>
          <tr key="thead">
            {columns.map((column, index) => {
              return (
                <th
                  key={index + "" + column.name}
                  scope="col"
                  onClick={() => handleSort(column.name)}
                >
                  {column.label}
                  {column.label !== "" &&
                    typeof column.label === "string" &&
                    enableSorting &&
                    sortableKey &&
                    sortableKey?.length > 0 &&
                    sortableKey.includes(column.label) && (
                      <IoIosArrowDown
                        className={`sort-icon ${
                          sortColumn === column.name
                            ? sortOrder === "asc"
                              ? "rotate180"
                              : "rotate"
                            : ""
                        }`}
                      />
                    )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!isLoading && rows && rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <AssignmentLate fontSize="large" />
                <p className="data-text">No Data Found</p>
              </td>
            </tr>
          )}
          {isLoading && <SkeltonPlaceholder columns={columns} />}
          {!isLoading &&
            enablePagination &&
            prepareRows(paginatedData, columns)}
          {!isLoading && !enablePagination && prepareRows(rows, columns)}
        </tbody>
      </table>
      {enablePagination && (
        <GridPagination
          count={noOfPageCount || 0}
          showOnPage={showOnPage || 10}
          onChange={handlePageNumberClick}
          handleSelectShowOnPage={handleSelectShowOnPage}
          page={selectedPage || 1}
        />
      )}
    </div>
  );
};
