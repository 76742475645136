import { Skeleton } from "@mui/material";

type SkeltonPlaceholderProps = {
  columns: any;
};

export const SkeltonPlaceholder = ({ columns }: SkeltonPlaceholderProps) => {
  const getSkeltonPlaceholderBasedOnTheColumnCount = () => {
    return columns.map((t: any, i: number) => {
      return (
        <td key={t + i}>
          <Skeleton variant="rectangular" height={18} />
        </td>
      );
    });
  };
  return (
    <>
      <tr>{getSkeltonPlaceholderBasedOnTheColumnCount()}</tr>
      <tr>{getSkeltonPlaceholderBasedOnTheColumnCount()}</tr>
      <tr>{getSkeltonPlaceholderBasedOnTheColumnCount()}</tr>
      <tr>{getSkeltonPlaceholderBasedOnTheColumnCount()}</tr>
      <tr>{getSkeltonPlaceholderBasedOnTheColumnCount()}</tr>
    </>
  );
};
