import { useContext } from "react";
import { Outlet } from "react-router-dom";

// Internal
import { GlobalContextData } from "../../context";
import { LoadingOverlay } from "../../components";
import "./AuthLayout.scss";

export const AuthLayout = () => {
  const { state } = useContext(GlobalContextData);

  return (
    <>
      <div className="auth-layout-container">
        {state?.loadingState?.isLoading && (
          <LoadingOverlay loadingText={state.loadingState.loadingText || ""} />
        )}
        <Outlet />
      </div>
    </>
  );
};
